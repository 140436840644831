import { type FC } from "react";
import { Modal, Image, type ModalProps, Button } from "react-bootstrap";

interface Props extends ModalProps {
  onConfirm: () => void;
}

export const PracticeConfirmationModal: FC<Props> = ({ onConfirm, ...modalProps }) => {
  return (
    <Modal {...modalProps} className="mt-5">
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <Image src={"/images/pozor-na-tohle.svg"} width={120} loading={"eager"} />
        </div>
        <div className="text-center mb-5">
          <p className="h3">Úlohy, které spouštíš, budou tzv. „nanečisto“</p>
          <p>
            Tady si procvičíš konkrétní téma, které ti nejde. Ale procvičování nanečisto se nepočítá do denního tréninku
            a nesplníš tak řadu.
          </p>
          <p>Trénink pro splnění řady spustíš jedině z dashabordu na úvodní stránce.</p>
        </div>
        <Button className="w-100" onClick={onConfirm}>
          Ok, pokračovat
        </Button>
      </Modal.Body>
    </Modal>
  );
};
