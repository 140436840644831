import { Trans, useTranslation } from "react-i18next";
import Logo from "images/logos/tonatrenujes.svg";

import { Alert, Col, Container, Image, Row } from "react-bootstrap";

const NotAuthenticated = () => {
  const { t } = useTranslation(["auth"]);

  return (
    <Container className="container-mw-sm">
      <Row>
        <Col className={"py-5 text-center"}>
          <Image src={Logo} loading={"lazy"} className={"mb-40px"} />

          <Alert variant={"danger"} className={"text-start"}>
            <p>
              {t("heading")}
              <br />
              <Trans
                i18nKey="info"
                ns="auth"
                components={{
                  lnk: <a href="https://zona.to-das.cz/" className="phone-consent__link" rel="noopener noreferrer" />,
                }}
              />
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default NotAuthenticated;
