import { useState, useEffect } from "react";
import type { FC } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CalendarWeek, ExerciseButtons } from "features";

import type { GetDashboardDataQuery, StudyDayFragment, WorstTopicsFragment } from "api/generated";

import { useAppSelector } from "store/hooks";
import { getUserEmail, selectAdmissionsDeadline, selectDateOfRegistration } from "store/slices/user";

import { formatDate } from "utils/calendarDateFormatting";
import { getSessionFromStorage } from "utils/sessionStorageHandler";

import strawberryIcon from "images/icons/checkberry.svg";
import snailIcon from "images/icons/drakusnail.svg";
import TagManager from "react-gtm-module";
import { capitalize } from "lodash";

const calculateInterruptedExerciseTime = (email: string | null | undefined) => {
  const sessionData = getSessionFromStorage(email);

  if (sessionData === null) return 0;

  return sessionData.spentTime;
};

interface Props {
  data: GetDashboardDataQuery | undefined;
}

const DashboardCalendarOverview: FC<Props> = ({ data }) => {
  const { t } = useTranslation(["dashboard", "exercise"]);
  const navigate = useNavigate();
  const today = formatDate(new Date());
  const userEmail = useAppSelector(getUserEmail);
  const admissionsDeadline = useAppSelector(selectAdmissionsDeadline);
  const dateOfRegistration = useAppSelector(selectDateOfRegistration);

  const [trainTopics, setTrainTopics] = useState<WorstTopicsFragment[]>([]);
  const [weekData, setWeekData] = useState<{
    studyDays: StudyDayFragment[];
    tests: string[];
    admissionsDeadline: string;
    dateOfRegistration: string | null;
  }>({
    studyDays: [],
    tests: [],
    admissionsDeadline: "",
    dateOfRegistration: null,
  });

  const interruptedExerciseTime = calculateInterruptedExerciseTime(userEmail);

  useEffect(() => {
    if (data === undefined || data.timetable === undefined || admissionsDeadline === "undefined") return;

    const studyDays: StudyDayFragment[] = [];
    for (const timetable of data.timetable.timetable) {
      for (const plan of timetable.plan) {
        studyDays.push(plan);
      }
    }

    const tests = data.testPresence.map(presence => presence.dateTime.split(" ")[0]);
    setWeekData({ studyDays, tests, admissionsDeadline, dateOfRegistration });

    const worstTopics = [...data.userTopicsWorst];
    if (Array.isArray(worstTopics)) {
      setTrainTopics(worstTopics.sort((a, b) => (a.abilityScoreAll ?? 0) - (b.abilityScoreAll ?? 0)));
    }
  }, [data, admissionsDeadline, dateOfRegistration]);

  const todayResult = weekData.studyDays.find(day => day.date === today);

  const redirectExercise = () => {
    TagManager.dataLayer({ dataLayer: { event: "start-exercise" } });

    navigate("/cviceni");
  };

  const isTrainingFinished = todayResult?.sessionFinished === true;

  const isTrainingPartiallyDone =
    todayResult !== undefined &&
    typeof todayResult.duration === "number" &&
    todayResult.duration > 0 &&
    todayResult.sessionFinished !== true &&
    interruptedExerciseTime > 0;

  const getTexts = () => {
    if (isTrainingFinished) {
      return {
        icon: strawberryIcon,
        heading: t("training.done.heading"),
        description: t("training.done.description", {
          topicName: trainTopics.at(0)?.topic?.name?.toLocaleLowerCase() ?? "",
        }),
      };
    }

    if (isTrainingPartiallyDone) {
      return {
        icon: snailIcon,
        heading: t("training.partially.heading"),
        description: t("training.partially.description"),
      };
    }

    return {
      icon: snailIcon,
      heading: t("training.notStarted.heading"),
      description: t("training.notStarted.description"),
    };
  };

  const { icon, heading, description } = getTexts();
  return (
    <div className="well">
      <div className="pt-6px">
        <div className="px-3 pt-8px">
          <CalendarWeek data={weekData} />
        </div>
      </div>
      <hr />
      <div className="pt-12px px-16px px-sm-28px pb-32px">
        <Row className="g-12px">
          <Col xs={"auto"} style={{ width: 50 }} className={"text-center"}>
            <Image src={icon} />
          </Col>
          <Col>
            <p className={"fw-bold mb-8px"}>{heading}</p>
            <p className={"mb-12px"}>{description}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={"auto"} style={{ width: 50 }} className={"d-none d-sm-block"}></Col>
          <Col className={"well__btn"}>
            {isTrainingFinished ? (
              <ExerciseButtons topics={trainTopics} />
            ) : isTrainingPartiallyDone ? (
              <Button onClick={redirectExercise}>{capitalize(t("btn.finishTraining"))}</Button>
            ) : (
              <Button onClick={redirectExercise}>{capitalize(t("btn.startExercise"))}</Button>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DashboardCalendarOverview;
