import { Breadcrumb, Container, Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useTranslation } from "react-i18next";
import IconArrowBack from "../../../components/Icons/IconArrowBack/IconArrowBack";
import { Link } from "react-router-dom";

import phoneImage from "images/logos/phone.svg";
import { useAppSelector } from "store/hooks";
import { getGrade } from "store/slices/user";

const Timetable = () => {
  const grade = useAppSelector(getGrade);

  const isFiveOrGraduade = grade === "5" || grade === "maturant";
  const { t } = useTranslation(["common", "settings"]);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-sm"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/"}>
                <IconArrowBack />
                {t("pageNameHome")}
              </Link>
            </li>
          </Breadcrumb>
          <h1 className={"mb-0"}>{t("pageNameSettings")}</h1>
        </Container>
      </div>

      <Container className={"container-mw-sm page-container pt-25px pt-sm-45px"}>
        <Nav className={"nav-menu"}>
          <NavItem>
            <LinkContainer to="/nastaveni/zmena-telefonu">
              <Nav.Link className={"nav-link--with-arrow"}>
                {t("pageNameConnections")}
                <img src={phoneImage} alt={t("connections.phone", { ns: "settings" })} />
              </Nav.Link>
            </LinkContainer>
          </NavItem>

          <NavItem>
            <LinkContainer to="/nastaveni/zvuk">
              <Nav.Link className={"nav-link--with-arrow"}>{t("pageNameBackgroundSound")}</Nav.Link>
            </LinkContainer>
          </NavItem>
        </Nav>

        {!isFiveOrGraduade && (
          <>
            <hr className={"my-4"} />
            <p className="text-center">
              <strong>{t("webinarHeading", { ns: "settings" })}</strong>
            </p>
            <a
              className={"btn btn-primary w-100"}
              href="https://zona.to-das.cz/_u_webinars.php"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t("webinarButton", { ns: "settings" })}
            </a>
          </>
        )}

        <hr className={"my-4"} />

        <a
          className={"btn btn-primary w-100"}
          href="https://zona.to-das.cz/_login.php"
          target="_blank"
          rel="noreferrer noopener"
        >
          {t("personalArea", { ns: "settings" })}
        </a>
      </Container>
    </>
  );
};

export default Timetable;
