import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Layout from "./_Layout";

import { AnswerPractice } from "components";
import { PracticeSelfEvaluation } from "features";

import { useLazyGetAssignmentAnswersQuery } from "api/generated";
import type { UserAssignmentDetailFragment } from "api/generated";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { getAnswerVariants, getSimpleAnswer, setAnswer, setAssignmentEvaluation } from "store/slices/topicPractice";
import { setIsTimerShown } from "store/slices/timer";

import { evaluateSorting, getSolutionVariant } from "utils/getSolutionVariant";
import { useSavePracticeSelfEvaluation } from "utils/hooks/useSavePracticeSelfEvaluation";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { eventEmitter, Events } from "utils/events/EventEmitter";
import { processApiError } from "utils/processApiError";
import { useEmptyAssignmentAnswer } from "utils/hooks/useEmptyAssignmentAnswer";

/* rPp7XgOY9DxQXaSbZsBQ */

interface Props {
  data: UserAssignmentDetailFragment;
  onNextClick: () => void;
}

const Razeni: FC<Props> = ({ data, onNextClick }) => {
  const options =
    data.assignment?.assignment?.questions.map(question => {
      return question.options.map(option => option.value);
    }) ?? [];
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const savePracticeSelfEvaluation = useSavePracticeSelfEvaluation();
  const dispatch = useAppDispatch();
  const selected = useAppSelector(getSimpleAnswer(data.id));
  const answerVariants = useAppSelector(getAnswerVariants(data.id));

  const [trigger, { data: answerData, isLoading, error: apiLazyError }] = useLazyGetAssignmentAnswersQuery();
  const [answers, setAnswers] = useState<Array<Array<string | null>> | undefined>(undefined);
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);
  const { setEmptyPracticeAnswer } = useEmptyAssignmentAnswer();

  useEffect(() => {
    const defaultValue = new Array(data.assignment?.assignment?.questions.length).fill(" ".repeat(options[0].length));
    dispatch(setAnswer({ assignmentId: data.id, answers: defaultValue }));
    eventEmitter.dispatch(Events.ONBOARDING_UPDATE);
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);

    setEmptyPracticeAnswer(data.id, evaluateAnswers);
    setAnswers([]);
  }, [apiLazyError]);

  useEffect(() => {
    if (answerData === undefined || answerData.assignments.length === 0) return;
    const responseAnswers =
      answerData.assignments[0]?.assignment?.questions.map(question =>
        question.correctAnswers
          .filter((answer): answer is string => answer !== null)[0]
          .split("")
          .map(value =>
            data.assignment?.assignment?.isAnswerCaseSensitive === true ? [value] : [value.toLocaleLowerCase()],
          ),
      ) ?? [];

    dispatch(setIsTimerShown(false));
    dispatch(
      setAssignmentEvaluation({
        assignmentId: data.id,
        status: evaluateSorting(selected[0]?.split(""), responseAnswers[0]),
        answerVariants: evaluateAnswers(responseAnswers[0]),
      }),
    );
    setAnswers(responseAnswers.flat());
  }, [answerData]);

  const evaluateAnswers = (answers: Array<Array<string | null>>) => {
    if (data.assignment?.assignment === undefined || data.assignment.assignment === null) return [];

    const selectedChars = selected[0]?.split("");
    return data.assignment.assignment.questions[0]?.options.map((_option, index) => {
      return getSolutionVariant(
        selectedChars[index],
        answers,
        index,
        data.assignment?.assignment?.isAnswerCaseSensitive ?? false,
      );
    });
  };

  const onSubmit = () => {
    if (data.assignment?.id === undefined) return;

    trigger({ assignmentId: data.assignment.id }).catch(error => {
      console.error(error);
    });
  };

  const handleCheck = (index: number, position: number, newValue: string | null | undefined) => {
    if (typeof newValue === "string") {
      const copy = [...selected];
      const currentQuestion = copy[index].split("");
      currentQuestion[position] = newValue;
      copy[index] = currentQuestion.join("");

      dispatch(setAnswer({ assignmentId: data.id, answers: copy }));
    }
  };
  const areAnswersFilled = () => {
    return (
      selected.length > 0 &&
      selected.every(answer => {
        return answer.split("").every(char => char !== " ");
      })
    );
  };

  if (isSelfEvaluation) {
    if (data.assignment === undefined || data.assignment === null) return null;

    return (
      <PracticeSelfEvaluation
        userAssignment={data}
        userAssignmentId={data.id}
        onNextClick={onNextClick}
        saveAnswer={savePracticeSelfEvaluation}
      />
    );
  }

  return (
    <Layout
      onSubmit={onSubmit}
      isAnalysis={answers !== undefined}
      goNext={onNextClick}
      isSubmitDisabled={!areAnswersFilled() || isLoading}
    >
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className="mt-20px">
        {data.assignment?.assignment?.questions.map((question, qIndex) => {
          return (
            <div key={`wrapper-${qIndex}`}>
              <Latex>{question.heading ?? ""}</Latex>

              {question.options.map((option, oIndex) => {
                return (
                  <div
                    key={`text-${qIndex}-${oIndex}`}
                    className="assignment-option"
                    dangerouslySetInnerHTML={{ __html: option.additionalText ?? "" }}
                  />
                );
              })}

              <div className="question question--list mt-20px" {...(qIndex === 0 ? { id: "question" } : {})}>
                {options[qIndex].map((_values, vIndex) => {
                  if (answers === undefined) {
                    return (
                      <div key={`question-${qIndex}-${vIndex}`} className="button-group">
                        {selected.length > 0 ? (
                          <>
                            <span>{`${vIndex + 1}.`}</span>
                            <ButtonGroup className={"btn-group-select btn-shadow"}>
                              {options[qIndex].map((option, rIndex) => {
                                const currentValue = selected[qIndex][vIndex] ?? "";
                                return (
                                  <ToggleButton
                                    key={rIndex}
                                    id={`radio-${qIndex}-${vIndex}-${rIndex}`}
                                    type="radio"
                                    variant="select"
                                    value={option ?? ""}
                                    checked={currentValue === option}
                                    onChange={() => {
                                      handleCheck(qIndex, vIndex, option);
                                    }}
                                  >
                                    {option}
                                  </ToggleButton>
                                );
                              })}
                            </ButtonGroup>
                          </>
                        ) : null}
                      </div>
                    );
                  }

                  const selectedString = qIndex in selected ? selected[qIndex] : "";

                  if (data.assignment === undefined || data.assignment === null) return null;

                  return (
                    <div key={`answer-${vIndex}`} className="button-group">
                      <span>{`${vIndex + 1}.`}</span>
                      <AnswerPractice
                        key={`answer-${qIndex}`}
                        index={qIndex}
                        assignment={data.assignment}
                        type={answerVariants?.[vIndex] ?? "incorrect"}
                        userAssignmentId={data.id}
                        subject={data.subject?.appName}
                      >
                        {selectedString[vIndex]}
                      </AnswerPractice>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Razeni;
