import { useEffect, useState } from "react";
import { getFromLocalStorage, saveToLocalStorage } from "utils/localStorage";

// This hook is used to manage the visibility of the practice confirmation modal
export const usePracticeConfirmationModalLocalStorage = () => {
  const [hasSeenModal, setHasSeenModal] = useState<number>(0);

  // Load the value from localStorage on the first render
  useEffect(() => {
    const hasSeenModalLS = getFromLocalStorage("practice-confirmation-modal");
    if (typeof hasSeenModalLS === "number") {
      setHasSeenModal(hasSeenModalLS);
    }
  }, []);

  // This effect listens to changes in localStorage and updates the state accordingly
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "practice-confirmation-modal") {
        const newValue = event.newValue != null ? JSON.parse(event.newValue) : 0;
        setHasSeenModal(newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // Save the new value to localStorage
  const setSeenModalToLocalStorage = () => {
    const newValue = hasSeenModal + 1;
    setHasSeenModal(newValue);
    saveToLocalStorage("practice-confirmation-modal", newValue);
  };

  return { isConfirmationModalAllowed: hasSeenModal < 3, setSeenModalToLocalStorage };
};
