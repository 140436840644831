import { useEffect, useState } from "react";
import { Button, Breadcrumb, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Loading, PracticeConfirmationModal, TopicBox } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";

import { useGetSuperTopicTopicsQuery } from "api/generated";

import { parseCombinedTopic, sortTopicsByName } from "utils/sortTopics";
import type { IParsedTopic } from "utils/sortTopics";
import { processApiError } from "utils/processApiError";
import { usePracticeConfirmationModalLocalStorage } from "components/Modal/PracticeConfirmationModal/usePracticeConfirmationModalLocalStorage";

const SuperTopics = () => {
  const { t } = useTranslation(["common"]);
  const { superId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  if (typeof superId !== "string" || superId?.length === 0) {
    navigate("/");
    return null;
  }

  const { data: topicData, isLoading, error: apiError } = useGetSuperTopicTopicsQuery({ superTopicId: superId });

  const [topics, setTopics] = useState<IParsedTopic[]>([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const { isConfirmationModalAllowed, setSeenModalToLocalStorage } = usePracticeConfirmationModalLocalStorage();

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  const getTopicHref = (topicId: string) => {
    return `/ulohy/${superId}/${topicId}`;
  };

  useEffect(() => {
    if (topicData === undefined || topicData.superTopicsCached.length === 0) return;

    const parsedData: IParsedTopic[] = [];
    const topics = topicData.superTopicsCached[0]?.combinedTopics;

    for (const topic of topics) {
      const parsed = parseCombinedTopic(topic, topicData.userCombinedTopicsCached, getTopicHref);
      if (parsed !== null) parsedData.push(parsed);
    }

    setTopics(sortTopicsByName(parsedData));
  }, [topicData]);

  if ((topicData?.superTopicsCached === undefined || topicData.superTopicsCached.length !== 1) && !isLoading)
    return null;

  const title = topicData === undefined ? "" : topicData.superTopicsCached[0]?.name;

  const handlePracticeStart = (superId: string) => {
    dispatch(removeResults());
    navigate(`/procvicovani/superokruh/${superId}`);
  };

  const handleSetModalVisible = () => {
    setSeenModalToLocalStorage();
    setModalVisible(true);
  };

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={"/ulohy"}>
                <IconArrowBack />
                {t("back", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-3"}>{title !== "" ? title : "\u00A0"}</h1>

          <Button
            className={"text-uppercase"}
            onClick={() => {
              isConfirmationModalAllowed ? handleSetModalVisible() : handlePracticeStart(superId);
            }}
            disabled={
              topicData?.userAssignmentsCached.totalCount === undefined ||
              topicData.userAssignmentsCached.totalCount === 0
            }
          >
            {t("trainThisTypeOfAssignments", { ns: "assignments" })}
          </Button>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"}>
        {isLoading ? (
          <Loading />
        ) : (
          <div>
            {topics.length === 0 ? (
              <div>Kde nic, tu nic</div>
            ) : (
              topics.map(topic => (
                <TopicBox key={topic.topicName} topic={topic} showStars={true} envelopeCounts={topic.envelopes} />
              ))
            )}
          </div>
        )}
      </Container>
      <PracticeConfirmationModal
        onConfirm={() => {
          handlePracticeStart(superId);
        }}
        show={isModalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default SuperTopics;
