import { useEffect, useRef, useState } from "react";
import type { FC, ReactNode } from "react";

import { Loading } from "components";
import { NotAuthenticated } from "features";

import { useQuery } from "utils/hooks/useQuery";
import { getFromLocalStorage, saveToLocalStorage } from "utils/localStorage";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setToken,
  selectParentToken,
  setParentToken,
  selectTeacherEvaluationToken,
  setTeacherEvaluationToken,
} from "store/slices/token";

import { useLoginMutation } from "api/api";

import { HANDSHAKE_QUERY_PARAM, ACCESS_TOKEN_PARAM, PARENT_TOKEN_PARAM, TEACHER_EVALUATION_TOKEN_PARAM } from "const";

import { processApiError } from "utils/processApiError";

import { ReactComponent as BgWaveTop } from "images/bg/bckg-branded-top.svg";

interface Props {
  children: ReactNode;
}

const Authenticator: FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const token = getFromLocalStorage(ACCESS_TOKEN_PARAM);
  const parentToken = useAppSelector(selectParentToken);
  const teacherEvaluationToken = useAppSelector(selectTeacherEvaluationToken);

  const { getSearchParam, hideSearchParams } = useQuery();
  const [login, { error: apiMutationError }] = useLoginMutation();

  const [authDone, setAuthDone] = useState(false);
  const userIsBeingLoggedIn = useRef(false);
  const queryAccessToken = getSearchParam(HANDSHAKE_QUERY_PARAM);
  const queryParentToken = getSearchParam(PARENT_TOKEN_PARAM);
  const queryTeacherEvaluationToken = getSearchParam(TEACHER_EVALUATION_TOKEN_PARAM);

  useEffect(() => {
    if (apiMutationError === undefined) return;

    processApiError(apiMutationError);
  }, [apiMutationError]);

  useEffect(() => {
    if (authDone) return;

    if (queryTeacherEvaluationToken !== null) {
      processTeacherEvaluation(queryTeacherEvaluationToken);
      setAuthDone(true);
      return;
    }

    if (process.env.REACT_APP_LOGIN_USER !== undefined) {
      processAdmin(process.env.REACT_APP_LOGIN_USER);
      return;
    }

    if (queryAccessToken !== null) {
      processUser(queryAccessToken);
      return;
    }

    if (queryParentToken !== null) {
      processParent(queryParentToken);
      setAuthDone(true);
      return;
    }

    const storageAccessToken = getFromLocalStorage(ACCESS_TOKEN_PARAM);
    if (storageAccessToken !== null) {
      dispatch(setToken(storageAccessToken));
      setAuthDone(true);
      return;
    }

    const storageParentToken = getFromLocalStorage(PARENT_TOKEN_PARAM);
    if (storageParentToken !== null) {
      dispatch(setParentToken(storageParentToken));
      setAuthDone(true);
      return;
    }

    const storageTeacherEvalutionToken = getFromLocalStorage(TEACHER_EVALUATION_TOKEN_PARAM);
    if (storageParentToken !== null) {
      dispatch(setTeacherEvaluationToken(storageTeacherEvalutionToken));
      setAuthDone(true);
      return;
    }

    updateToken(null);
    updateParentToken(null);
    updateTeacherEvaluationToken(null);

    setAuthDone(true);
  }, [queryAccessToken, queryParentToken, queryTeacherEvaluationToken]);

  const processAdmin = (token: string) => {
    setAuthDone(true);
    dispatch(setToken(token));
    saveToLocalStorage(ACCESS_TOKEN_PARAM, token);
  };

  const processUser = (token: string) => {
    updateParentToken(null);
    updateTeacherEvaluationToken(null);

    if (userIsBeingLoggedIn.current) return;
    userIsBeingLoggedIn.current = true;

    login({ token })
      .then(response => {
        if ("data" in response && typeof response.data.login.accessToken === "string") {
          updateToken(response.data.login.accessToken);
        } else {
          console.error(response);
          updateToken(null);
        }
      })
      .catch(error => {
        console.error(error);
        updateToken(null);
      })
      .finally(() => {
        userIsBeingLoggedIn.current = false;
        setAuthDone(true);
        hideSearchParams();
      });
  };

  const processParent = (token: string) => {
    updateToken(null);
    updateTeacherEvaluationToken(null);

    saveToLocalStorage(PARENT_TOKEN_PARAM, token ?? undefined);
    dispatch(setParentToken(token));

    setAuthDone(true);
    hideSearchParams();
  };

  const processTeacherEvaluation = (token: string) => {
    updateToken(null);
    updateParentToken(null);

    saveToLocalStorage(TEACHER_EVALUATION_TOKEN_PARAM, token ?? undefined);
    dispatch(setTeacherEvaluationToken(token));

    setAuthDone(true);
    hideSearchParams();
  };

  const updateToken = (token: string | null) => {
    saveToLocalStorage(ACCESS_TOKEN_PARAM, token ?? undefined);
    dispatch(setToken(token));

    if (token !== null) {
      updateParentToken(null);
      updateTeacherEvaluationToken(null);
    }
  };

  const updateParentToken = (token: string | null) => {
    dispatch(setParentToken(queryParentToken));

    if (token !== null) {
      updateToken(null);
      updateTeacherEvaluationToken(null);
    }
  };

  const updateTeacherEvaluationToken = (token: string | null) => {
    dispatch(setTeacherEvaluationToken(queryTeacherEvaluationToken));

    if (token !== null) {
      updateToken(null);
      updateParentToken(null);
    }
  };

  if (!authDone) {
    return (
      <div className={"fullpage bg-primary-subtle"}>
        <BgWaveTop className={"wave__top"} />

        <Loading />
      </div>
    );
  }

  if (
    (typeof token === "string" && token.length > 0) ||
    (typeof parentToken === "string" && parentToken.length > 0) ||
    (typeof teacherEvaluationToken === "string" && teacherEvaluationToken.length > 0)
  ) {
    return <>{children}</>;
  }

  return <NotAuthenticated />;
};

export default Authenticator;
