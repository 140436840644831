import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store/store";

import type { UserType, UpdateUserPreferencesMutationVariables } from "api/generated";

interface IUserState extends UserType {
  tempPhoneNumber: string | null;
  isSoundMuted: boolean; // Used to mute sound when modal is opened in current practice or exercise session
  timetableCreatedDate: string | null;
}

const initialState: IUserState = {
  preferredSchools: [],
  onboarding: {
    introduced: false,
    finished: false,
    welcome: false,
  },
  tempPhoneNumber: null,
  isSoundMuted: false,
  timetableCreatedDate: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      state.admissionsDeadline = action.payload.admissionsDeadline;
      state.preferredSchools = action.payload.preferredSchools;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.grade = action.payload.grade;
      state.vocative = action.payload.vocative;
      state.sex = action.payload.sex;
      state.testsToUnlock = action.payload.testsToUnlock;
      state.testsToBuy = action.payload.testsToBuy;
      state.preferredSubject = action.payload.preferredSubject;
      state.withSound = action.payload.withSound;
      state.isSoundMuted = false;
      state.phoneNumber = action.payload.phoneNumber;
      state.onboarding = {
        introduced: action.payload.onboarding.introduced,
        finished: action.payload.onboarding.finished,
        welcome: action.payload.onboarding.welcome,
      };
      state.smsEnabled = action.payload.smsEnabled;
      state.firstLogin = action.payload.firstLogin;
      state.sessionsAchieved = action.payload.sessionsAchieved;
      state.phoneValidationStatus = action.payload.phoneValidationStatus;
      state.phoneValidationDatetimeLast = action.payload.phoneValidationDatetimeLast;
      state.tempPhoneNumber = action.payload.phoneValidationPhoneNumber ?? null;
      state.daysFulfillsPlan = action.payload.daysFulfillsPlan ?? 0;
      state.elixirOfLifeCount = action.payload.elixirOfLifeCount ?? 0;
    },

    setPhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneNumber = action.payload;
    },
    setTempPhoneNumber: (state, action: PayloadAction<string>) => {
      state.tempPhoneNumber = action.payload;
      state.phoneValidationStatus = "pending";
    },
    setPhoneValidationDateTimeLast: (state, action: PayloadAction<string>) => {
      state.phoneValidationDatetimeLast = action.payload;
    },
    validatePhoneNumber: (state, action: PayloadAction<string>) => {
      state.phoneValidationStatus = action.payload;

      if (action.payload === "accepted") {
        state.phoneNumber = state.tempPhoneNumber;
        state.tempPhoneNumber = null;
      }
    },
    setSound: (state, action: PayloadAction<boolean>) => {
      state.withSound = action.payload;
    },
    setIsSoundMuted: (state, action: PayloadAction<boolean>) => {
      state.isSoundMuted = action.payload;
    },
    setPreferences: (state, action: PayloadAction<UpdateUserPreferencesMutationVariables>) => {
      state.tempPhoneNumber = action.payload.phoneNumber;
      state.preferredSubject = action.payload.preferredSubject;
    },
    resetOnboarding: state => {
      state.phoneNumber = undefined;
      state.onboarding.finished = false;
      state.onboarding.introduced = false;
      state.onboarding.welcome = false;
    },
    setOnboardingIntroduced: state => {
      state.onboarding = {
        introduced: true,
        finished: false,
        welcome: state.onboarding.welcome,
      };
    },
    setOnboardingFinished: state => {
      state.onboarding = {
        introduced: true,
        finished: true,
        welcome: state.onboarding.welcome,
      };
    },
    setWelcomed: state => {
      state.onboarding.welcome = true;
    },
    updateSessionsCount: (state, action: PayloadAction<number>) => {
      state.sessionsAchieved = action.payload;
    },
    setTimetableCreatedDate: (state, action: PayloadAction<string>) => {
      state.timetableCreatedDate = action.payload;
    },
    setSmsEnabled: (state, action: PayloadAction<boolean>) => {
      state.smsEnabled = action.payload;
    },
  },
});

export default userSlice.reducer;
export const {
  resetOnboarding,
  setOnboardingIntroduced,
  setOnboardingFinished,
  setUser,
  setPhoneNumber,
  setSmsEnabled,
  setTempPhoneNumber,
  validatePhoneNumber,
  setPreferences,
  setSound,
  setIsSoundMuted,
  setWelcomed,
  updateSessionsCount,
  setTimetableCreatedDate,
  setPhoneValidationDateTimeLast,
} = userSlice.actions;

export const selectSound = (state: RootState) => state.user.withSound;
export const selectIsSoundMuted = (state: RootState) => state.user.isSoundMuted;
export const selectPhoneNumber = (state: RootState) => state.user.phoneNumber;
export const selectSmsEnabled = (state: RootState) => state.user.smsEnabled ?? false;
export const selectTempPhoneNumber = (state: RootState) => state.user.tempPhoneNumber;
export const selectPreferredSchools = (state: RootState) => state.user.preferredSchools;
export const selectFirstPreferredSchool = (state: RootState) => {
  return state.user.preferredSchools.length > 0 ? state.user.preferredSchools[0] : null;
};
export const selectPreferredSubject = (state: RootState) => state.user.preferredSubject;
export const selectOnboardingIntroduced = (state: RootState) => state.user.onboarding.introduced;
export const selectOnboardingFinished = (state: RootState) => state.user.onboarding.finished;
export const selectUserWelcomed = (state: RootState) => state.user.onboarding.welcome;
export const selectUserAddressing = (state: RootState) => state.user.vocative;
export const selectUserName = (state: RootState) => state.user.name;
export const selectAdmissionsDeadline = (state: RootState) => state.user.admissionsDeadline;
export const selectDateOfRegistration = (state: RootState) => state.user.firstLogin;
export const buyTestsCount = (state: RootState) => state.user.testsToBuy;
export const unlockTestsCount = (state: RootState) => state.user.testsToUnlock;
export const selectSessionCount = (state: RootState) => state.user.sessionsAchieved;
export const selectOnboardingStatus = createSelector(
  [selectPhoneNumber, selectOnboardingFinished, selectOnboardingIntroduced],
  (phoneNumber, finished, introduced) => {
    return {
      introduced,
      phoneNumber,
      finished,
    };
  },
);
export const getFirstLogin = (state: RootState) => state.user.firstLogin;
export const getTimetableCreateDate = (state: RootState) => state.user.timetableCreatedDate;
export const getPhoneValidationStatus = (state: RootState) => state.user.phoneValidationStatus;
export const getPhoneValidationDatetimeLast = (state: RootState) => state.user.phoneValidationDatetimeLast;
export const getUserEmail = (state: RootState) => state.user.email;
export const getElixitOfLife = (state: RootState) => state.user.elixirOfLifeCount;
export const getDaysFulfillsPlan = (state: RootState) => state.user.daysFulfillsPlan;
export const getGrade = (state: RootState) => state.user.grade;
