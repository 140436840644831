import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function FeedbackForm() {
  const { t } = useTranslation(["sentryFeedback"]);

  useEffect(() => {
    Sentry.feedbackIntegration({
      colorScheme: "system",
      buttonLabel: t("buttonLabel", { ns: "sentryFeedback" }),
      formTitle: t("formTitle", { ns: "sentryFeedback" }),
      nameLabel: t("nameLabel", { ns: "sentryFeedback" }),
      namePlaceholder: t("namePlaceholder", { ns: "sentryFeedback" }),
      emailLabel: t("emailLabel", { ns: "sentryFeedback" }),
      emailPlaceholder: t("emailPlaceholder", { ns: "sentryFeedback" }),
      messageLabel: t("messageLabel", { ns: "sentryFeedback" }),
      messagePlaceholder: t("messagePlaceholder", { ns: "sentryFeedback" }),
      submitButtonLabel: t("submitButtonLabel", { ns: "sentryFeedback" }),
      cancelButtonLabel: t("cancelButtonLabel", { ns: "sentryFeedback" }),
      successMessageText: t("successMessageText", { ns: "sentryFeedback" }),
      useSentryUser: {
        name: "name",
        email: "email",
      },
    }).createWidget();

    return () => {
      // Remove the feedback widget when the component is unmounted
      Sentry.feedbackIntegration().remove();
    };
  }, []);

  return null;
}
