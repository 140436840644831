import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import { useEffect, useState } from "react";
import type { FC } from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

import Layout from "./_Layout";

import { AbilityScore, AnswerExercise } from "components";
import { ExerciseSelfEvaluation } from "features";

import { useLazyGetAssignmentAnswersQuery, useCheckAnswersMutation } from "api/generated";
import type { UserAssignmentForExerciseFragment } from "api/generated";

import { evaluateSorting, getSolutionVariant } from "utils/getSolutionVariant";

import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  setAnswer,
  setAssignmentEvaluation,
  setTimeLearn,
  getAssignmentResult,
  setAbilityScore,
  setTips,
} from "store/slices/exercise";
import {
  getAssignmentTime,
  startAssignmentTimer,
  resetAssignmentTimer,
  stopAssignmentTimer,
  setIsTimerShown,
} from "store/slices/timer";

import { useSaveExerciseSelfEvaluation } from "utils/hooks/useSaveExerciseSelfEvaluation";
import { getTimeToLearn } from "utils/getTimeToLearn";
import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";
import { isSelfEvaluationForEveryAssignment } from "utils/valueTranslators";
import { processAnswerResponse } from "utils/processAnswerResponse";
import { isAbilityScoreShown } from "utils/isAbilityScoreShown";
import { changeDistinctAbilityScore, getAbilityScoreOptions } from "utils/changeAbilityScore";
import { processApiError, processExerciseError, logGeneralExerciseError } from "utils/processApiError";
import { useEmptyAssignmentAnswer } from "utils/hooks/useEmptyAssignmentAnswer";

/* rPp7XgOY9DxQXaSbZsBQ */

interface Props {
  data: UserAssignmentForExerciseFragment;
  userAssignmentId: string;
  evaluateAnswer: boolean;
  onNextClick: () => void;
}

const Razeni: FC<Props> = ({ data, userAssignmentId, evaluateAnswer, onNextClick }) => {
  const questionsCount = data.assignment?.assignment?.questions.length ?? 0;

  const options =
    data.assignment?.assignment?.questions.map(question => {
      return question.options.map(option => option.value);
    }) ?? [];
  const isSelfEvaluation = data.assignment?.scoring?.scoringMethod?.scoringMethod === "sebeopravení";

  const saveExerciseSelfEvaluation = useSaveExerciseSelfEvaluation();
  const dispatch = useAppDispatch();
  const assignmentResults = useAppSelector(getAssignmentResult<string>(userAssignmentId));
  const time = useAppSelector(getAssignmentTime);
  const { imageUrl } = useImageUrlResolver(data.assignment?.assignment?.assignmentImage);
  const { setEmptyExerciseAnswer } = useEmptyAssignmentAnswer();

  const [sendAnswers, { error: apiMutationError, isLoading: isSendingAnswers }] = useCheckAnswersMutation();
  const [trigger, { data: answerData, isLoading: isGettingAnswers, error: apiLazyError }] =
    useLazyGetAssignmentAnswersQuery();

  const [submit, setSubmit] = useState(false);
  const [goNext, setGoNext] = useState(false);
  const [isRequestsLoading, setRequestsLoading] = useState(false);
  const [assignmentPoints, setAssignmentPoints] = useState<{ points: number | null; maxPoints: number | null }>({
    points: null,
    maxPoints: null,
  });

  useEffect(() => {
    dispatch(resetAssignmentTimer());

    if (assignmentResults?.answers === undefined || assignmentResults.answers.length === 0) {
      const defaultValue = new Array(data.assignment?.assignment?.questions.length).fill(" ".repeat(options[0].length));
      dispatch(setAnswer({ assignmentId: userAssignmentId, answer: defaultValue }));
    }

    if (assignmentResults?.tips === undefined || assignmentResults.tips.length === 0) {
      dispatch(setTips({ userAssignmentId, tips: new Array(questionsCount).fill(false) }));
    }

    dispatch(startAssignmentTimer());

    return () => {
      dispatch(resetAssignmentTimer());
    };
  }, []);

  useEffect(() => {
    if (apiLazyError === undefined) return;

    processApiError(apiLazyError);

    setEmptyExerciseAnswer(userAssignmentId, evaluateAnswers);
  }, [apiLazyError]);

  useEffect(() => {
    processExerciseError(apiMutationError, {
      assignmentId: userAssignmentId,
      selfEvaluation: null,
      userAnswers: assignmentResults?.answers ?? [],
      timeToSolve: time,
    });
  }, [apiMutationError]);

  useEffect(() => {
    setRequestsLoading(isGettingAnswers && isSendingAnswers);
  }, [isGettingAnswers, isSendingAnswers]);

  useEffect(() => {
    let pendingRequestTimeout: NodeJS.Timeout | null = null;

    if (!isGettingAnswers) return;

    pendingRequestTimeout = setTimeout(() => {
      setEmptyExerciseAnswer(userAssignmentId, evaluateAnswers);
      setRequestsLoading(false);
    }, 5000);

    return () => {
      if (pendingRequestTimeout !== null) {
        clearTimeout(pendingRequestTimeout);
      }
    };
  }, [isGettingAnswers]);

  useEffect(() => {
    if (answerData === undefined || answerData.assignments.length === 0) return;
    const responseAnswers =
      answerData.assignments[0]?.assignment?.questions.map(
        question =>
          question.correctAnswers
            .filter((answer): answer is string => answer !== null)[0]
            ?.split("")
            .map(value =>
              data.assignment?.assignment?.isAnswerCaseSensitive === true ? [value] : [value.toLocaleLowerCase()],
            ),
      ) ?? [];

    if (assignmentResults === undefined || assignmentResults.answers === undefined) return;

    dispatch(
      setAssignmentEvaluation({
        assignmentId: userAssignmentId,
        status: evaluateSorting(assignmentResults.answers[0]?.split(""), responseAnswers[0]),
        answerVariants: evaluateAnswers(responseAnswers[0]),
        correctAnswers: responseAnswers.flat(),
      }),
    );
  }, [answerData]);

  useEffect(() => {
    if (!submit) return;

    if (typeof data.assignment?.id === "string") {
      trigger({ assignmentId: data.assignment.id }).catch(error => {
        console.error(error);
      });
    }

    if (assignmentResults === undefined || assignmentResults.answers === undefined || !evaluateAnswer) return;

    sendAnswers({
      assignmentId: userAssignmentId,
      selfEvaluation: null,
      userAnswers: assignmentResults.answers.map(answer => {
        return {
          answers: [answer ?? ""],
        };
      }),
      timeToSolve: time,
    })
      .then(response => {
        processAnswerResponse(response, data);

        if ("data" in response) {
          setAssignmentPoints({ points: response.data.answer.scoreReal, maxPoints: 1 });
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        dispatch(resetAssignmentTimer());
        dispatch(startAssignmentTimer());
        setSubmit(false);
      });
  }, [submit]);

  useEffect(() => {
    if (goNext) {
      dispatch(setTimeLearn({ assignmentId: userAssignmentId, timeLearn: time }));
      onNextClick();
      setGoNext(false);
    }
  }, [goNext]);

  const evaluateAnswers = (answers: Array<Array<string | null>>) => {
    if (
      data.assignment === undefined ||
      data.assignment === null ||
      data.assignment?.assignment === undefined ||
      data.assignment?.assignment === null
    )
      return [];

    if (assignmentResults === undefined || assignmentResults.answers === undefined) return [];

    const selectedChars = assignmentResults.answers[0]?.split("");
    return data.assignment.assignment.questions[0]?.options.map((_option, index) => {
      return getSolutionVariant(
        selectedChars[index],
        answers,
        index,
        data.assignment?.assignment?.isAnswerCaseSensitive ?? false,
      );
    });
  };

  const handleCheck = (index: number, position: number, newValue: string | null | undefined) => {
    if (typeof newValue === "string") {
      if (assignmentResults === undefined || assignmentResults.answers === undefined) return;

      const copy = [...assignmentResults.answers];
      const currentQuestion = copy[index].split("");
      currentQuestion[position] = newValue;
      copy[index] = currentQuestion.join("");

      dispatch(setAnswer({ assignmentId: userAssignmentId, answer: copy }));
    }
  };

  const onSubmit = () => {
    setSubmit(true);
    dispatch(setIsTimerShown(false));
  };

  const handleNextClick = () => {
    dispatch(stopAssignmentTimer());
    setGoNext(true);
  };

  const areAnswersFilled = () => {
    try {
      const answers = assignmentResults?.answers;
      const areFilled =
        answers !== undefined &&
        answers.length > 0 &&
        answers.every(answer => {
          return answer.split("").every(char => char !== " ");
        });
      return areFilled;
    } catch (error) {
      logGeneralExerciseError(error, data?.assignment?.id, assignmentResults?.answers);
      return false;
    }
  };

  const handleAbilityScoreSelect = (value: number, index: number) => {
    if (
      data.assignment === undefined ||
      data.assignment === null ||
      !isSelfEvaluationForEveryAssignment(data.assignment)
    )
      return;

    const newAbilityScore = changeDistinctAbilityScore(value, index, assignmentResults?.abilityScore, questionsCount);
    dispatch(setAbilityScore({ assignmentId: userAssignmentId, score: newAbilityScore }));
  };

  const getAbilityScoreOnIndex = (index: number) => {
    const abilityScore = assignmentResults?.abilityScore;

    if (abilityScore === undefined || typeof abilityScore === "number") return 0;

    return abilityScore[index];
  };

  if (isSelfEvaluation) {
    if (data.assignment === undefined || data.assignment === null) return null;

    return (
      <ExerciseSelfEvaluation
        userAssignment={data}
        userAssignmentId={userAssignmentId}
        results={assignmentResults}
        onNextClick={handleNextClick}
        saveAnswer={saveExerciseSelfEvaluation}
        evaluateAnswer={evaluateAnswer}
      />
    );
  }

  return (
    <Layout
      onSubmit={onSubmit}
      isAnalysis={assignmentResults?.correctAnswers !== undefined}
      goNext={handleNextClick}
      isSubmitDisabled={!areAnswersFilled() || isRequestsLoading}
      userAssignmentId={userAssignmentId}
      showAbilityScore={isAbilityScoreShown(data.assignment?.scoring?.selfEvaluation.selfEvaluation)}
      points={assignmentPoints.points ?? null}
      maxPoints={assignmentPoints.maxPoints}
    >
      <Latex>{data.assignment?.assignment?.assignmentText ?? ""}</Latex>
      {imageUrl !== null ? <img src={imageUrl} /> : null}

      <div className="mt-20px">
        {data.assignment?.assignment?.questions.map((question, qIndex) => {
          return (
            <div key={`wrapper-${qIndex}`}>
              <Latex>{question.heading ?? ""}</Latex>

              {question.options.map((option, oIndex) => {
                return (
                  <div
                    key={`text-${qIndex}-${oIndex}`}
                    className="assignment-option"
                    dangerouslySetInnerHTML={{ __html: option.additionalText ?? "" }}
                  ></div>
                );
              })}
              <div className="question question--list mt-20px">
                {options[qIndex].map((_values, vIndex) => {
                  if (assignmentResults?.correctAnswers === undefined) {
                    return (
                      <div key={`question-${qIndex}-${vIndex}`} className="button-group">
                        {assignmentResults?.answers !== undefined && assignmentResults?.answers?.length > 0 ? (
                          <>
                            <span>{`${vIndex + 1}.`}</span>
                            <ButtonGroup className={"btn-group-select btn-shadow"}>
                              {options[qIndex].map((option, rIndex) => {
                                const currentValue = assignmentResults?.answers?.[qIndex][vIndex] ?? "";
                                return (
                                  <ToggleButton
                                    key={rIndex}
                                    id={`radio-${qIndex}-${vIndex}-${rIndex}`}
                                    type="radio"
                                    variant="select"
                                    value={option ?? ""}
                                    checked={currentValue === option}
                                    onChange={() => {
                                      handleCheck(qIndex, vIndex, option);
                                    }}
                                  >
                                    {option}
                                  </ToggleButton>
                                );
                              })}
                            </ButtonGroup>
                          </>
                        ) : null}
                      </div>
                    );
                  }

                  if (assignmentResults === undefined || assignmentResults.answers === undefined) return null;

                  const selectedString = qIndex in assignmentResults.answers ? assignmentResults?.answers[qIndex] : "";
                  const selfEvalOptions = getAbilityScoreOptions(
                    isSelfEvaluation,
                    assignmentResults?.answersVariants?.[qIndex],
                  );

                  return (
                    <>
                      <div key={`answer-${vIndex}`} className="button-group">
                        <span>{`${vIndex + 1}.`}</span>
                        <AnswerExercise
                          subject={data.subject?.appName}
                          key={`answer-${qIndex}`}
                          index={qIndex}
                          assignment={data.assignment}
                          userAssignmentId={data.id}
                          type={assignmentResults.answersVariants?.[vIndex] ?? "danger"}
                          timeToLearn={getTimeToLearn(data.timeToLearn)}
                          onNext={handleNextClick}
                        >
                          {selectedString[vIndex]}
                        </AnswerExercise>
                      </div>

                      {data.assignment !== undefined &&
                      data.assignment !== null &&
                      isSelfEvaluationForEveryAssignment(data.assignment) &&
                      question.showAbilityScore === true &&
                      selfEvalOptions !== null ? (
                        <>
                          <hr className={"my-25px"} />
                          <div className="mw-sm">
                            <AbilityScore
                              isSelfEvaluated={isSelfEvaluation}
                              options={selfEvalOptions}
                              selectedValue={getAbilityScoreOnIndex(qIndex)}
                              onValueSelect={(value: number) => {
                                handleAbilityScoreSelect(value, qIndex);
                              }}
                              index={qIndex}
                              key={qIndex}
                            />
                          </div>
                        </>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </Layout>
  );
};

export default Razeni;
