import "katex/dist/katex.min.css";

import Latex from "react-latex-next";
import type { FC } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SelfEvaluationOptionSelection from "./SelfEvaluationOptionSelection";

import type { TSolutionVariant } from "store/slices/exercise";

import type { AssignmentDetailFragment } from "api/generated";

import { useImageUrlResolver } from "utils/hooks/useImageUrlResolver";

interface Props {
  assignment: AssignmentDetailFragment;
  selectedOptions: Array<{ points: number | null; status: TSolutionVariant | null }>;
  isLoading: boolean;
  handleEvaluationSelect: (index: number, points: number) => void;
  onConfirm: () => void;
  userAssignmentId: string;
}

const SelfEvaluation: FC<Props> = ({ assignment, selectedOptions, isLoading, handleEvaluationSelect, onConfirm }) => {
  const { t } = useTranslation(["common"]);

  const isShared = assignment.selfEvaluation?.hasSharedSelfEvaluation === true;

  const areAllEvaluationsFilled = () => {
    if (isShared && assignment.selfEvaluation?.sharedSelfEvaluation?.options.length === 0) return true;

    return selectedOptions.every(value => value.status !== null);
  };

  const onSubmit = () => {
    if (areAllEvaluationsFilled()) {
      onConfirm();
    }
  };

  const sharedOptions = assignment?.selfEvaluation?.sharedSelfEvaluation?.options;
  if (selectedOptions.length === 0 || (isShared && (sharedOptions === undefined || sharedOptions.length === 0))) {
    return (
      <div>
        <p style={{ textAlign: "center" }}>
          <h1 style={{ color: "red" }}>Nejsou nastaveny volby pro sebehodnocení!</h1>
        </p>

        <div className="exercise__footer">
          <div className="mw-sm">
            <Button className={"w-100 text-uppercase"} onClick={onSubmit}>
              {t("continue")}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {isShared ? (
        <div className="mt-20px question">
          <SelfEvaluationOptionSelection
            selfEvaluationData={assignment.selfEvaluation?.sharedSelfEvaluation}
            score={selectedOptions[0]?.points}
            handleEvaluationSelect={handleEvaluationSelect}
            selectIndex={0}
          />
        </div>
      ) : (
        selectedOptions.map((selectedOption, selectIndex) => {
          const question = assignment.assignment?.questions[selectIndex];
          const { imageUrl } = useImageUrlResolver(question?.selfEvaluation?.general?.image);

          return (
            <div key={selectIndex} className="mb-3">
              <Latex>{question?.heading ?? ""}</Latex>
              {imageUrl !== null ? <img src={imageUrl} /> : null}

              <Form className="mt-20px question">
                <SelfEvaluationOptionSelection
                  selfEvaluationData={question?.selfEvaluation}
                  score={selectedOption.points}
                  handleEvaluationSelect={handleEvaluationSelect}
                  selectIndex={selectIndex}
                />
              </Form>
            </div>
          );
        })
      )}

      <div className="exercise__footer">
        <div className="mw-sm">
          <Button
            className={"w-100 text-uppercase"}
            onClick={onSubmit}
            disabled={!areAllEvaluationsFilled() || isLoading}
          >
            {t("continue")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SelfEvaluation;
