export const removeHTML = (text: string) => {
  // Sentry logged few errors, when text was undefined. Unable to reproduce.
  if (text === undefined) return "";

  const stripped = text.replaceAll(/<\/?[^>]+(>|$)/gi, "");

  const el = document.createElement("div");
  el.innerHTML = stripped;

  return el.textContent ?? el.innerText ?? "";
};

export const removeWrapperHTML = (text: string) => {
  return text.replace(/^<[^>]+>/, "").replace(/<\/[^>]+>$/, "");
};

export const containsLatex = (text: string) => {
  return /\$.*?\$/g.test(text);
};
