import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { getGrade } from "store/slices/user";

const PrepareSupplies = () => {
  const grade = useAppSelector(getGrade);

  const isFiveOrGraduade = grade === "5" || grade === "maturant";
  /* TODO: translate */
  return (
    <Alert variant={"info"}>
      <p>
        <strong>Připrav si</strong>
      </p>
      <ul>
        <li>čistý papír A4 a psací potřeby,</li>
        {!isFiveOrGraduade && (
          <li>
            <Link
              to={"https://kurzy.ssgh.cz/wp-content/uploads/2023/10/To-das_pomocne_vzorecky.pdf"}
              target="_blank"
              rel="noopener noreferrer"
            >
              vytištěné pomocné vzorečky
            </Link>
            ,
          </li>
        )}
        <li>rýsovací potřeby</li>
      </ul>
      <p>a jakmile budeš moct, jdeme do toho 👊🙂</p>
    </Alert>
  );
};

export default PrepareSupplies;
