import type { FC } from "react";
import { Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import { Trans, useTranslation } from "react-i18next";
import iconEnvelopeRed from "../../../images/icons/envelope-red.svg";
import iconEnvelopeGreen from "../../../images/icons/envelope-green.svg";
import iconEnvelopeOrange from "../../../images/icons/envelope-orange.svg";
import { Link } from "react-router-dom";

interface Props {
  isShown: boolean;
  onHide: () => void;
}

const EnvelopeExplanation: FC<Props> = ({ isShown, onHide }) => {
  const { t } = useTranslation(["common", "assignments"]);

  return (
    <Modal show={isShown} onHide={onHide}>
      <Modal.Header closeButton />
      <Modal.Body className={"pt-0 px-32px"}>
        <h4 className={"mb-12px"}>{t("subjects.howItWorks", { ns: "assignments" })}</h4>
        <p>{t("info.dashboardEnvelope.paragraph1", { ns: "exercise" })}</p>
        <p>
          <Trans i18nKey="info.dashboardEnvelope.paragraph2">
            Úlohy se ti rovnou rozřadí do obálek, takže hned uvidíš, co ti zatím moc nejde (červená obálka{" "}
            <Image src={iconEnvelopeRed} />
            ), kde naopak válíš (zelená obálka <Image src={iconEnvelopeGreen} />) a co umíš tak napůl (oranžová obálka{" "}
            <Image src={iconEnvelopeOrange} />
            ).
          </Trans>
        </p>
        <p className={"mb-20px"}>
          <Trans i18nKey="info.dashboardEnvelope.paragraph3">
            Pokud ti nevyhovuje žádný z vypsaných termínů přijímaček nanečisto, nebo chceš potrénovat ještě víc, můžeš
            si dokoupit{" "}
            <Link to={"https://zona.to-das.cz/_u_a_dashboard.php"} target={"_blank"}>
              samostatné testy s rozborem
            </Link>
            .
          </Trans>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default EnvelopeExplanation;
