import { useEffect, useRef, useState } from "react";
import { Breadcrumb, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { AssignmentBox, Loading, Pagination, PracticeConfirmationModal } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";
import type { IAssignmentTabAssignment } from "features/AssignmentsTab/AssignmentsTab";
import { AssignmentsTab } from "features";

import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";
import { setBookmarkQueryParams } from "store/slices/queryParams";

import { useGetBookmarksAndSubjectsQuery } from "api/generated";

import { ReactComponent as IconBookmark } from "images/icons/bookmark-colored.svg";

import { useQuery } from "utils/hooks/useQuery";
import { processApiError } from "utils/processApiError";
import { searchInAssignment } from "utils/searchInAssignment";

import { PAGE_QUERY_PARAM, SEARCH_QUERY_PARAM, SUBJECT_QUERY_PARAM } from "const";
import { usePracticeConfirmationModalLocalStorage } from "components/Modal/PracticeConfirmationModal/usePracticeConfirmationModalLocalStorage";

const Bookmarked = () => {
  const { t } = useTranslation(["common", "assignment"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { removeAllQueryParams, setEagerQueryParam, setSearchParam, searchParams } = useQuery();

  const fallbackText = t("bookmarked.nothingSaved", { ns: "assignments" });

  const { data, isLoading, error: apiError, refetch } = useGetBookmarksAndSubjectsQuery();
  const [allAssignments, setAllAssignments] = useState<IAssignmentTabAssignment[] | null>(null);
  const [foundAssignments, setFoundAssignments] = useState<IAssignmentTabAssignment[] | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const { isConfirmationModalAllowed, setSeenModalToLocalStorage } = usePracticeConfirmationModalLocalStorage();

  const subjects = useRef<string[]>([]);

  const search = searchParams.get(SEARCH_QUERY_PARAM);
  const pageString = searchParams.get(PAGE_QUERY_PARAM);
  const page = typeof pageString === "string" ? Number(pageString) : 1;

  const getTopicHref = (userAssignmentId: string) => {
    return `/ulozene/${userAssignmentId}`;
  };

  useEffect(() => {
    if (isLoading) return;
    refetch().catch(console.error);
  }, []);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    const searchParam = searchParams.get(SEARCH_QUERY_PARAM);
    const pageParam = searchParams.get(PAGE_QUERY_PARAM);
    const subjectParam = searchParams.get(SUBJECT_QUERY_PARAM);

    dispatch(
      setBookmarkQueryParams({
        ...(typeof searchParam === "string" ? { [SEARCH_QUERY_PARAM]: searchParam.toString() } : null),
        ...(typeof pageParam === "string" ? { [PAGE_QUERY_PARAM]: pageParam.toString() } : null),
        ...(typeof subjectParam === "string" ? { [SUBJECT_QUERY_PARAM]: subjectParam } : null),
      }),
    );
  }, [searchParams]);

  useEffect(() => {
    if (data === undefined) return;

    const subjectNames: string[] = [];

    for (const subject of data.subjects) {
      if (typeof subject.appName === "string") {
        subjectNames.push(subject.appName);
      }
    }

    subjects.current = subjectNames;

    const parsedAssignments: IAssignmentTabAssignment[] = [];

    for (const bookmark of data.bookmarks) {
      if (
        typeof bookmark.userAssignment.subject?.appName !== "string" ||
        bookmark.userAssignment.assignment?.isActive === undefined ||
        !bookmark.userAssignment.assignment?.isActive
      )
        continue;

      parsedAssignments.push({
        id: bookmark.userAssignment.assignment.id,
        text: bookmark.userAssignment.assignment?.assignment?.assignmentText ?? "",
        questionText: bookmark.userAssignment.assignment?.assignment?.questionsText ?? "",
        questionHeadings:
          bookmark.userAssignment.assignment?.assignment?.questions.map(question => question.heading ?? "") ?? [],
        href: getTopicHref(bookmark.userAssignment.id),
        testId: bookmark.userAssignment.assignment?.general?.admissionTest.name ?? null,
        testOrder: bookmark.userAssignment.assignment?.general?.testOrder ?? null,
        envelopeType: bookmark.userAssignment.envelope,
        subjectName: bookmark.userAssignment.subject?.appName,
      });
    }

    setAllAssignments(parsedAssignments);
  }, [data]);

  useEffect(() => {
    if (allAssignments === null) return;

    if (search === null || search.length < 1) {
      setFoundAssignments(null);
      return;
    }

    searchAssignments(search);
  }, [allAssignments]);

  useEffect(() => {
    if (allAssignments === null) return;

    if (search === null || search.length < 1) {
      if (searchParams.get(SEARCH_QUERY_PARAM) !== null) {
        removeAllQueryParams();
      }
      setFoundAssignments(null);

      return;
    }

    const timer = setTimeout(() => {
      searchAssignments(search);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const searchAssignments = (searchedString: string) => {
    if (allAssignments === null) return;

    const found: IAssignmentTabAssignment[] = [];

    for (const assignment of allAssignments) {
      if (searchInAssignment(assignment, searchedString)) {
        found.push(assignment);
      }
    }

    setFoundAssignments(found);
  };

  const handleSearch = (searched: string) => {
    setEagerQueryParam(SEARCH_QUERY_PARAM, searched);
  };

  const handlePageChange = (page: number) => {
    setSearchParam(PAGE_QUERY_PARAM, page.toString());
  };

  const handleTabChange = (tab: string) => {
    setEagerQueryParam(SUBJECT_QUERY_PARAM, tab);
  };

  const handlePracticeStart = () => {
    dispatch(removeResults());
    navigate("/procvicovani/ulozene");
  };

  const handleSetModalVisible = () => {
    setSeenModalToLocalStorage();
    setModalVisible(true);
  };

  return (
    <>
      <div className={"page-header page-header--has-tabs bookmarked"}>
        <Container className={"container-mw-md"}>
          <div className="bookmarked__header">
            <div>
              <Breadcrumb>
                <li className={"breadcrumb-item"}>
                  <Link to={"/"}>
                    <IconArrowBack />
                    {t("goHome")}
                  </Link>
                </li>
              </Breadcrumb>

              <h1>{t("pageTitle.savedExercises", { ns: "envelope" })}</h1>
            </div>

            <IconBookmark />
          </div>

          <Button
            className={"text-uppercase"}
            disabled={allAssignments === null || allAssignments.length === 0}
            onClick={() => {
              isConfirmationModalAllowed ? handleSetModalVisible() : handlePracticeStart();
            }}
          >
            {t("trainThisTypeOfAssignments", { ns: "assignments" })}
          </Button>

          <hr className={"my-25px"} />

          <Form
            onSubmit={event => {
              event.preventDefault();
            }}
          >
            <Form.Control
              className={"form-search"}
              placeholder={"Hledej v textu úlohy..."}
              value={search ?? ""}
              onChange={e => {
                handleSearch(e.target.value);
              }}
            />
          </Form>
        </Container>
      </div>

      <Container
        className={`container-mw-md page-container pt-25px pt-sm-45px flex-grow-1 ${
          !isLoading && foundAssignments !== null && foundAssignments.length === 0 ? "d-grid" : ""
        }`}
      >
        {isLoading || allAssignments === null ? (
          <Loading />
        ) : search === "" || search === null ? (
          <AssignmentsTab
            assignments={allAssignments}
            fallbackText={fallbackText}
            subjects={subjects.current}
            activeTab={searchParams.get(SUBJECT_QUERY_PARAM)}
            currentPage={page ?? 1}
            onPageChange={handlePageChange}
            onTabChange={handleTabChange}
          />
        ) : foundAssignments === null || foundAssignments.length === 0 ? (
          <div className="pt-20px">
            <p className={"mx-5 my-auto mt-sm-3 mb-sm-0 text-center"}>{t("searchNotFound", { ns: "common" })}</p>
          </div>
        ) : (
          <div className="pt-20px">
            {foundAssignments?.slice((page - 1) * 10, page * 10).map(assignment => {
              return <AssignmentBox key={assignment.href} assignment={assignment} />;
            })}

            {foundAssignments.length > 10 ? (
              <Pagination
                className={"mt-16px"}
                current={page}
                total={Math.ceil(foundAssignments.length / 10)}
                onNavigation={handlePageChange}
              />
            ) : null}
          </div>
        )}
      </Container>
      <PracticeConfirmationModal
        onConfirm={handlePracticeStart}
        show={isModalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default Bookmarked;
