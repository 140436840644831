import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";

import { getFromLocalStorage } from "utils/localStorage";

import { UserLoginDocument } from "api/generated";
import type { UserLoginMutation, UserLoginMutationVariables } from "api/generated";
import type { RootState } from "store/store";

import { ACCESS_TOKEN_PARAM, PARENT_TOKEN_PARAM, TEACHER_EVALUATION_TOKEN_PARAM } from "const";

const PARENT_ENDPOINTS = ["getParentRewards", "updateParentRewards"];
const TEACHER_EVALUATION_ENDPOINTS = ["solutionUploadUrl", "solutionUploadConfirm"];

const getToken = () => {
  const token = getFromLocalStorage(ACCESS_TOKEN_PARAM);
  if (token === null) {
    try {
      window.location.reload();
    } catch {
      // Firefox throws an error that is logged to sentry
    }
  }

  return token;
};

const getParentToken = () => {
  const parentToken = getFromLocalStorage(PARENT_TOKEN_PARAM);

  if (parentToken === null) {
    try {
      window.location.replace("/");
    } catch {
      // Firefox throws an error that is logged to sentry
    }
  }

  return parentToken;
};

const getTeacherEvaluationToken = () => {
  const teacherEvaluationToken = getFromLocalStorage(TEACHER_EVALUATION_TOKEN_PARAM);

  if (teacherEvaluationToken === null) {
    try {
      window.location.replace("/");
    } catch {
      // Firefox throws an error that is logged to sentry
    }
  }

  return teacherEvaluationToken;
};

const getUserEmail = (state: RootState) => {
  return state.user.email ?? "";
};

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: process.env.REACT_APP_GRAPHQL_ENDPOINT === undefined ? "" : process.env.REACT_APP_GRAPHQL_ENDPOINT,
    prepareHeaders: (headers, { getState, endpoint }) => {
      const state = getState() as RootState;

      switch (true) {
        case endpoint === "login":
          break;
        case PARENT_ENDPOINTS.includes(endpoint):
          headers.set("Parent-Token", getParentToken());
          break;
        case TEACHER_EVALUATION_ENDPOINTS.includes(endpoint):
          headers.set("Auth-Token", getTeacherEvaluationToken());
          break;
        default:
          headers.set("Auth-Token", getToken());
          headers.set("X-User-Email", getUserEmail(state));
      }

      return headers;
    },
  }),
  endpoints: build => ({
    login: build.mutation<UserLoginMutation, UserLoginMutationVariables>({
      query: variables => ({
        document: UserLoginDocument,
        variables,
      }),
    }),
  }),
});

export const { useLoginMutation } = api;
