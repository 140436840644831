import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import assignments from "./translations/cs/assignments.json";
import auth from "./translations/cs/auth.json";
import calendar from "./translations/cs/calendar.json";
import common from "./translations/cs/common.json";
import dashboard from "./translations/cs/dashboard.json";
import envelope from "./translations/cs/envelope.json";
import exercise from "./translations/cs/exercise.json";
import help from "./translations/cs/help.json";
import onboarding from "./translations/cs/onboarding.json";
import parentRewards from "./translations/cs/parent-rewards.json";
import settings from "./translations/cs/settings.json";
import statistics from "./translations/cs/statistics.json";
import teacherEvaluation from "./translations/cs/teacher-evaluation.json";
import sentryFeedback from "./translations/cs/sentry-feedback.json";

i18next
  .use(initReactI18next)
  .init({
    resources: {
      cs: {
        assignments,
        auth,
        calendar,
        common,
        dashboard,
        envelope,
        exercise,
        help,
        onboarding,
        parentRewards,
        settings,
        statistics,
        teacherEvaluation,
        sentryFeedback,
      },
    },
    debug: process.env.NODE_ENV === "development",
    lng: "cs", // if you're using a language detector, do not define the lng option
    fallbackLng: "cs",
    supportedLngs: ["cs"],
    ns: [
      "assignments",
      "auth",
      "calendar",
      "common",
      "dashboard",
      "edu",
      "research",
      "analysis",
      "parentRewards",
      "teacherEvaluation",
      "sentryFeedback",
    ],
    defaultNS: "common",
    fallbackNS: "common",
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
  .catch(error => {
    console.error(error);
  });

export default i18next;
