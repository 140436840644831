import { useEffect, useState } from "react";
import type { FormEvent } from "react";
import { Button, Breadcrumb, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AssignmentBox, Loading, Pagination, PracticeConfirmationModal } from "components";
import IconArrowBack from "components/Icons/IconArrowBack/IconArrowBack";

import { useAppDispatch } from "store/hooks";
import { removeResults } from "store/slices/topicPractice";
import { setAssignmentsListQueryParams } from "store/slices/queryParams";

import { useGetCombinedTopicAssignmentsQuery } from "api/generated";

import { useQuery } from "utils/hooks/useQuery";
import { processApiError } from "utils/processApiError";
import { searchInAssignment } from "utils/searchInAssignment";
import type { ISearchAssignment } from "utils/searchInAssignment";

import { PAGE_QUERY_PARAM, SEARCH_QUERY_PARAM } from "const";
import { usePracticeConfirmationModalLocalStorage } from "components/Modal/PracticeConfirmationModal/usePracticeConfirmationModalLocalStorage";

interface IAssignmentData extends ISearchAssignment {
  id: string;
}

const SuperTopics = () => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const { superId, combinedId } = useParams();

  if (superId === undefined || combinedId === undefined) {
    navigate("/");
    return null;
  }

  const dispatch = useAppDispatch();
  const { removeAllQueryParams, setEagerQueryParam, setSearchParam, searchParams } = useQuery();

  const {
    data: topicData,
    isLoading,
    error: apiError,
  } = useGetCombinedTopicAssignmentsQuery({ combinedTopicId: combinedId });

  const [allAssignments, setAllAssignments] = useState<IAssignmentData[] | null>(null);
  const [foundAssignments, setFoundAssignments] = useState<IAssignmentData[] | null>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const { isConfirmationModalAllowed, setSeenModalToLocalStorage } = usePracticeConfirmationModalLocalStorage();

  const search = searchParams.get(SEARCH_QUERY_PARAM);
  const pageString = searchParams.get(PAGE_QUERY_PARAM);
  const currentPage = typeof pageString === "string" ? Number(pageString) : 1;

  useEffect(() => {
    search === null ? setFoundAssignments(allAssignments) : searchAssignments(search);
  }, [allAssignments]);

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    const searchParam = searchParams.get(SEARCH_QUERY_PARAM);
    const pageParam = searchParams.get(PAGE_QUERY_PARAM);

    dispatch(
      setAssignmentsListQueryParams({
        topicId: combinedId,
        params: {
          ...(typeof searchParam === "string" ? { [SEARCH_QUERY_PARAM]: searchParam } : null),
          ...(typeof pageParam === "string" ? { [PAGE_QUERY_PARAM]: pageParam } : null),
        },
      }),
    );
  }, [searchParams]);

  const searchAssignments = (searchedString: string) => {
    if (allAssignments === null) return;

    const found: IAssignmentData[] = [];

    for (const assignment of allAssignments) {
      if (searchInAssignment(assignment, searchedString)) {
        found.push(assignment);
      }
    }

    setFoundAssignments(found);
  };

  useEffect(() => {
    if (topicData === undefined) {
      setAllAssignments([]);
      return;
    }

    const assignmentsData = topicData.userAssignmentsCached.items;
    if (assignmentsData === undefined) return;

    const parsedAssignments: IAssignmentData[] = [];

    for (const assignment of assignmentsData) {
      if (assignment.assignment?.isActive !== true) continue;

      parsedAssignments.push({
        id: assignment.id,
        text: assignment.assignment?.assignment?.assignmentText ?? "",
        questionText: assignment.assignment?.assignment?.questionsText ?? "",
        questionHeadings: assignment.assignment?.assignment?.questions.map(question => question.heading ?? "") ?? [],
        href: getTopicHref(assignment.id),
        testId: assignment.assignment?.general?.admissionTest.name ?? null,
        testOrder: assignment.assignment?.general?.testOrder ?? null,
        envelopeType: assignment.envelope,
      });
    }

    setAllAssignments(parsedAssignments);
  }, [topicData]);

  useEffect(() => {
    if (allAssignments === null) return;

    if (search === null || search.length < 1) {
      if (searchParams.get(SEARCH_QUERY_PARAM) !== null) {
        removeAllQueryParams();
      }
      setFoundAssignments(allAssignments);

      return;
    }

    const timer = setTimeout(() => {
      searchAssignments(search);
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const title = topicData === undefined ? "" : topicData.combinedTopicsCached[0]?.name ?? "";

  const getTopicHref = (assignmentId: string) => {
    return `/ulohy/${superId}/${combinedId}/${assignmentId}`;
  };

  const handleSearch = (searched: string) => {
    setEagerQueryParam(SEARCH_QUERY_PARAM, searched);
  };

  const handlePageChange = (page: number) => {
    setSearchParam(PAGE_QUERY_PARAM, page.toString());
  };

  const handlePracticeStart = () => {
    dispatch(removeResults());
    navigate(`/procvicovani/nadokruh/${combinedId}`);
  };

  const handleSetModalVisible = () => {
    setSeenModalToLocalStorage();
    setModalVisible(true);
  };

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const pagesCount = foundAssignments === null ? 1 : Math.ceil(foundAssignments.length / 10);

  return (
    <>
      <div className={"page-header"}>
        <Container className={"container-mw-md"}>
          <Breadcrumb>
            <li className={"breadcrumb-item"}>
              <Link to={`/ulohy/${superId}`}>
                <IconArrowBack />
                {t("back", { ns: "common" })}
              </Link>
            </li>
          </Breadcrumb>

          <h1 className={"mb-3"}>{title !== "" ? title : "\u00A0"}</h1>

          <Button
            className={"text-uppercase"}
            onClick={() => {
              isConfirmationModalAllowed ? handleSetModalVisible() : handlePracticeStart();
            }}
            disabled={
              topicData?.userAssignmentsCached.items === undefined || topicData.userAssignmentsCached.items.length === 0
            }
          >
            {t("trainThisTypeOfAssignments", { ns: "assignments" })}
          </Button>
        </Container>
      </div>

      <Container className={"container-mw-md page-container pt-25px pt-sm-45px"} fluid>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Form className="mb-3" onSubmit={submitForm}>
              <Form.Control
                className={"form-search"}
                placeholder={"Hledej v textu úlohy..."}
                value={search ?? ""}
                onChange={event => {
                  handleSearch(event.target.value);
                }}
              />
            </Form>
            {(foundAssignments ?? []).slice((currentPage - 1) * 10, currentPage * 10).map(assignment => {
              return <AssignmentBox key={assignment.id} assignment={assignment} />;
            })}
            {pagesCount > 1 ? (
              <Pagination
                className={"mt-16px"}
                current={currentPage}
                total={pagesCount}
                onNavigation={handlePageChange}
              />
            ) : null}
          </>
        )}
      </Container>
      <PracticeConfirmationModal
        show={isModalVisible}
        onHide={() => {
          setModalVisible(false);
        }}
        onConfirm={handlePracticeStart}
      />
    </>
  );
};

export default SuperTopics;
