import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from "recharts";
import Button from "react-bootstrap/Button";

import { Loading } from "components";

import { useGetTestPresencesQuery } from "api/generated";

import { useAppSelector } from "store/hooks";
import { selectPreferredSchools } from "store/slices/user";

import { formatShortDate } from "utils/calendarDateFormatting";
import { processApiError } from "utils/processApiError";

import { ReactComponent as IconArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "images/icons/arrow-right.svg";

const RESULTS_SHOWN = 5;

interface IChartItem {
  name: Date;
  score: number;
  testType: string;
  scoreBreakdown: {
    czech: number;
    math: number;
  };
}

const schoolColors = ["#27AE60", "#17A4C2", "#9E9B8E"];

const TestsGraph = () => {
  const { t } = useTranslation(["common", "statistics"]);

  const { data, isLoading, error: apiError } = useGetTestPresencesQuery();
  const schools = useAppSelector(selectPreferredSchools);
  const parsedData = useRef<IChartItem[]>([]);

  const [stateData, setStateData] = useState<{ chartData: IChartItem[]; offset: number }>({ chartData: [], offset: 0 });

  useEffect(() => {
    if (apiError === undefined) return;

    processApiError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (data === undefined) return;

    const formattedData: IChartItem[] = data.testPresence
      .filter(test => test.absolved === true)
      .map(test => {
        const dateOfTest = new Date(test.dateTime);
        const scoreCzech = Math.max(test.scoreCzech ?? 0, 0);
        const scoreMath = Math.max(test.scoreMath ?? 0, 0);

        return {
          name: dateOfTest,
          score: scoreCzech + scoreMath,
          testType: test.form ?? "",
          scoreBreakdown: {
            czech: scoreCzech,
            math: scoreMath,
          },
        };
      })
      .sort((a, b) => a.name.getTime() - b.name.getTime());

    parsedData.current = formattedData;
    setStateData({
      chartData: formattedData.slice(-RESULTS_SHOWN),
      offset: 0,
    });
  }, [data]);

  const showPrevValue = () => {
    if (stateData.offset + RESULTS_SHOWN >= parsedData.current.length) return;

    setStateData(currentState => {
      const newOffset = currentState.offset + 1;
      return {
        offset: newOffset,
        chartData: parsedData.current.slice(-(newOffset + RESULTS_SHOWN)).slice(0, RESULTS_SHOWN),
      };
    });
  };

  const showNextValue = () => {
    if (stateData.offset <= 0) return;

    setStateData(currentState => {
      const newOffset = currentState.offset - 1;
      return {
        offset: newOffset,
        chartData: parsedData.current.slice(-(newOffset + RESULTS_SHOWN)).slice(0, RESULTS_SHOWN),
      };
    });
  };

  const lineChartsPopover = ({ payload }: { payload: any }) => {
    const popoverDate = new Date(payload?.name);

    return (
      <Popover className={"line-graph__popover"}>
        <Popover.Body>
          <Row className={"mb-4px g-8px"}>
            <Col xs={12}>
              <p className={"mb-0 fw-bold"}>{`${popoverDate?.getDate()}. ${popoverDate?.getMonth() + 1}.`}</p>
            </Col>
          </Row>

          <Row className={"mb-4px g-8px"}>
            <Col xs={6}>
              <p className={"mb-0"}>Počet bodů:</p>
            </Col>
            <Col xs={6}>
              <p className={"mb-0"}>
                {Math.max(payload?.score, 0)} (M {Math.max(payload?.scoreBreakdown?.math, 0)}, ČJ{" "}
                {Math.max(payload?.scoreBreakdown?.czech, 0)})
              </p>
            </Col>
          </Row>

          <Row className={"g-8px"}>
            <Col xs={6}>
              <p className={"mb-0"}>Typ testu:</p>
            </Col>
            <Col xs={6}>
              <p className={"mb-0"}>{payload?.testType}</p>
            </Col>
          </Row>

          <Row className={"mt-12px"}>
            <Col xs={12}>
              <Button
                onClick={() => {
                  window.open(
                    "https://zona.to-das.cz/_u_parents-school-admissions.php",
                    "_blank",
                    "noopener,noreferrer",
                  );
                }}
              >
                {"Podívat se na detailní rozbor"}
              </Button>
            </Col>
          </Row>
        </Popover.Body>
      </Popover>
    );
  };

  const getMaxAcceptancePoints = () => {
    const points = schools.map(school => school.pointsForAcceptance ?? 0);
    return Math.max(...points);
  };

  if (isLoading) return <Loading />;

  return (
    <>
      <h4 className={"mb-3"}>{t("sectionTitle.practiceExams", { ns: "statistics" })}</h4>

      <div className="graph py-sm-32px line-graph">
        <div className="graph__header mb-16px">
          <Button
            variant="tertiary"
            className="btn-shadow"
            onClick={showPrevValue}
            disabled={stateData.offset + RESULTS_SHOWN >= parsedData.current.length}
          >
            <IconArrowLeft className={"icon"} />
          </Button>
          <p className="fw-bold mb-0">
            {stateData.chartData.length > 0
              ? `${formatShortDate(stateData.chartData[0]?.name)} - ${formatShortDate(
                  stateData.chartData[stateData.chartData.length - 1].name,
                )}`
              : null}
          </p>
          <Button variant="tertiary" className="btn-shadow" onClick={showNextValue} disabled={stateData.offset === 0}>
            <IconArrowRight className={"icon"} />
          </Button>
        </div>

        <div className="line-graph__wrapper">
          <ResponsiveContainer width="100%" height={434}>
            <LineChart
              width={500}
              height={300}
              data={stateData.chartData}
              margin={{
                top: 15,
                right: 15,
                left: 15,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#DEDCD6" />
              <XAxis
                dataKey="name"
                stroke="#DEDCD6"
                padding={{
                  left: 36,
                  right: 36,
                }}
                tickFormatter={tick => {
                  const tickDate = new Date(tick);

                  return `${tickDate.getDate()}. ${tickDate.getMonth() + 1}.`;
                }}
              />
              <YAxis
                stroke="#DEDCD6"
                unit={" b."}
                domain={[
                  (dataMin: number) => {
                    if (!isFinite(dataMin)) return 0;
                    return dataMin - (dataMin % 5);
                  },
                  (dataMax: number) => {
                    if (!isFinite(dataMax)) return 50;
                    return Math.max(dataMax + (5 - (dataMax % 5)), getMaxAcceptancePoints(), 50);
                  },
                ]}
              />

              <Legend
                layout={"vertical"}
                align={"center"}
                verticalAlign={"bottom"}
                content={({ payload }) => {
                  const legendarySchools = payload?.filter(item => item.type !== "none");

                  return (
                    <ul className={"recharts-default-legend"}>
                      <li className={"recharts-legend-item"}>
                        <p className={"mb-0 fw-bold text-center"}>Hranice pro přijetí:</p>
                      </li>
                      {Array.isArray(legendarySchools) && legendarySchools.length > 0 ? (
                        legendarySchools?.map(function (item, index) {
                          return (
                            <li className={"recharts-legend-item"} key={index}>
                              <svg className="recharts-surface" width="22" height="22" viewBox="0 0 22 22">
                                <line
                                  strokeWidth="3"
                                  fill="none"
                                  stroke={item?.color}
                                  strokeDasharray="3 3"
                                  x1="0"
                                  y1="11"
                                  x2="22"
                                  y2="11"
                                  className="recharts-legend-icon"
                                ></line>
                              </svg>
                              <span className={"recharts-legend-item-text"}>{item?.value}</span>
                            </li>
                          );
                        })
                      ) : (
                        <p dangerouslySetInnerHTML={{ __html: t("statistics.noSchoolsSet", { ns: "statistics" }) }}></p>
                      )}
                    </ul>
                  );
                }}
              />

              {schools.map((school, index) => {
                if (typeof school.name !== "string" || typeof school.pointsForAcceptance !== "number") return null;
                return (
                  <ReferenceLine
                    key={index}
                    y={school.pointsForAcceptance}
                    stroke={schoolColors[index]}
                    strokeWidth={3}
                    strokeDasharray="3 3"
                  />
                );
              })}

              {schools.map((school, index) => {
                if (typeof school.name !== "string" || typeof school.pointsForAcceptance !== "number") return null;
                return (
                  <Line
                    key={index}
                    dataKey={""}
                    name={school.name}
                    stroke={schoolColors[index]}
                    strokeWidth={3}
                    strokeDasharray="3 3"
                    legendType={"plainline"}
                  />
                );
              })}

              <Line
                dataKey="score"
                stroke="#A75FE0"
                strokeLinecap={"round"}
                strokeWidth={5}
                legendType={"none"}
                activeDot={false}
                dot={dot => {
                  return (
                    <OverlayTrigger
                      trigger={["click", "focus"]}
                      placement="bottom"
                      rootClose
                      defaultShow={false}
                      key={dot.payload.name}
                      overlay={lineChartsPopover({ payload: dot?.payload })}
                    >
                      <circle
                        r="5"
                        stroke="#A75FE0"
                        strokeLinecap="round"
                        strokeWidth="12"
                        fill="#A75FE0"
                        width={dot?.width}
                        height={dot?.height}
                        strokeOpacity="0.4"
                        cx={dot?.cx}
                        cy={dot?.cy}
                        className="recharts-dot recharts-line-dot"
                      />
                    </OverlayTrigger>
                  );
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

export default TestsGraph;
